import { BrowserModule, Title } from '@angular/platform-browser';
// import { CommonModule } from "@angular/common";
// CommonModule exported by BrowserModule
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';

import { AppComponent } from './app.component';

import { ProductModule } from './reviews/product.module';

// import { ProductModule } from './products/product.module';
// import { ProductModule } from './mycomps/myproducts/product.module';

import { InjectionToken, NgModule } from '@angular/core';

import { NgbdCollapseBasic } from './mycomps/shared/collapse-basic';
import { MyRegionsComponent } from './mycomps/myregions/myregions.component';
import { MyWelcomeComponent } from './mycomps/mywelcome/mywelcome.component';

import { MyAccordion01 } from './mycomps/mywelcome/myaccordion01.component';
import { MyAccordion02 } from './mycomps/mywelcome/myaccordion02.component';
import { MyAccordion03 } from './mycomps/mywelcome/myaccordion03.component';

import { ExternalLinkDirective } from './external-link.directive';
import { PageNotFoundComponent } from './mycomps/shared/page-not-found.component';

const deactivateGuard = new InjectionToken('deactivateGuard');

export const routes: Routes = [
  { path: 'about', component: MyRegionsComponent },
  { path: 'regions', component: MyRegionsComponent },
  { path: '', component: MyWelcomeComponent },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    MyWelcomeComponent,
    MyRegionsComponent,
    NgbdCollapseBasic,
    MyAccordion01,
    MyAccordion02,
    MyAccordion03,
    ExternalLinkDirective,
    PageNotFoundComponent
  ],
  imports: [BrowserModule, NgbModule, ProductModule,
    HttpClientModule, FormsModule, RouterModule.forRoot(routes)],
  providers: [
    {
      provide: deactivateGuard,
      useValue: () => {
        console.log('Guard function is called!')
        return false;
      }
    },
    Title

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
