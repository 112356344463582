import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';

@Component({
  selector: 'myaccordion02',
  templateUrl: './myaccordion02.component.html',
})
export class MyAccordion02 {
  public constructor(private titleService: Title) { }
  public review08 = '8';
  public review02 = '2';
  //note: need to specify id in links on hompeage so routerlink works

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
