import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'myaccordion03',
  templateUrl: './myaccordion03.component.html',
})
export class MyAccordion03 {
  public constructor(private titleService: Title) { }
  public review09 = '9';
  public review08 = '8';
  public review05 = '5';
  public review03 = '3';
  public review02 = '2';

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


}
