<div class="parallaxDiv">
  <div class="bgimg-1">
    <div class="caption">
      <!-- <p class="captionP">
        <span class="border">SUPPORT LOCAL SPORT VENUES!</span>
      </p> -->
      <p class="captionP"><span class="sfborder">
          <a (click)="setTitle( 'Support Local Venues' )" [routerLink]="['/reviews', review10]"
            class=" captionLink ">SUPPORT LOCAL
            SPORT VENUES!</a>
        </span>
      </p>
      <a routerLink="/reviews" type=" button" aria-controls="collapseExample" class="btn btn-brown mar12_0_0"
        aria-expanded="false">
        Reviews List »</a>

    </div>
  </div>
</div>

<div class=" sfusJumbo">
  <div class="row sfusRow text-left">
    <div class="col-xs-12 col-md-6">
      <hr class="sfusHR" />
      <h3 class="sfus_H">SMALL BUSINESSES NEED OUR SUPPORT</h3>
      <hr class="sfusHR" />
      <div class="sfusVideoWrapper">
        <!-- <a [routerLink]="['/reviews', product.productId]">
          <a [routerLink]="['/reviews', '10']" class="spDrop01" ></a> -->
        <a (click)="setTitle( 'Support Local Venues' )" [routerLink]="['/reviews', review10]" class="spDrop01">
          <img class="sfusVidThumb01 " src="./assets/images/supportThumb02Brown400w.png"
            alt="Sportfans Support Small Businesses" />
        </a>

      </div>
      <p class="mar12_0_12_0">
        <!-- <strong>Small Businesses Need Our Help </strong><br /> -->
        <span>
          Small businesses that support local sports are struggling and they
          need our help. Whether sponsoring little leage teams, or providing a
          comfortable place to meet with friends, small businesses help
          communities thrive.
        </span>
      </p>
      <div class="shareButton text-center mar12_0_12_0">
        <a [href]="'https://sportfans.us/sfans/suggest-a-venue/'" class="btn btn-lg btn-brown"> Suggest a
          Venue »</a>
      </div>
    </div>
    <div class="col-xs-12 col-md-6">



      <hr class="sfusHR" />
      <h3 class="sfus_H">SELECT A VENUE BY REGION</h3>
      <hr class="sfusHR" />
      <div id="demo-accordion-collapse" role="tablist">
        <div class="row row-no-gutters sfusRow text-left">
          <div class="col-12">


            <div class="card-body spcomSelect">
              <div class="barNav">
                <div class="spDownDrop">
                  <!-- <button class="dropbtn"> -->
                  <select class="cd-block dropbtn">
                    <option value="">Select a Region...</option>

                  </select>
                  <div class="spDownDrop-content">
                    <a id="ct-sw" class="spDrop01" [href]="'https://sportfans.us/sfans/regions/ct-sw/'">CT-SW</a>
                    <a id="ny-li" class="spDrop01" [href]="'https://sportfans.us/sfans/regions/ny-li/'">NY-LI</a>
                    <a id="ny-ny" class="spDrop01" [href]="'http://sportfans.us/sfans/regions/ny-ny/'">NY-NY</a>
                    <a id="ny-se" class="spDrop01" [href]="'https://sportfans.us/sfans/regions/ny-se/'">NY-SE</a>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>




      <hr class="sfusHR" />
      <h3 class="sfus_H">VIDEO REVUES BY CITY</h3>
      <hr class="sfusHR" />
      <div id="demo-accordion-collapse" role="tablist">
        <div class="row row-no-gutters sfusRow text-left">
          <div class="col-12">
            <div class="card">
              <div class="card-header" role="tab" id="accord1">
                <p class="cardP"> A few cities with great sport venues are reviewed below. Venues reviewed
                  prior to March 2020 may not be currently open. Sport fans are encouraged to comment on venues listed
                  they have visited and <a
                    [href]="'https://sportfans.us/sfans/where-are-the-best-places-to-catch-a-game/'"
                    class="sfansHref01">suggest venues</a>
                  to add that are missing from the list. </p>
                <!-- <p>LEFT-POINTING DOUBLE ANGLE QUOTATION MARK &#xBB;
                         Right-POINTING DOUBLE ANGLE QUOTATION MARK &#xAB;</p> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row row-no-gutters sfusRow text-left">
          <div class="col-6 div-no-rt-gutter">
            <myaccordion01></myaccordion01>
            <ngbd-collapse-basic></ngbd-collapse-basic>
          </div>

          <div class="col-6 div-no-left-gutter">
            <myaccordion02></myaccordion02>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sectionDiv_02 sfusJumbo"></div>

<div class="sectionDiv_02 sfusJumbo">
  <div class="row sfusRow text-left">
    <div class="col-xs-12 col-md-6">
      <hr class="sfusHR" />
      <h3 class="sfus_H">SPORTFAN TOPICS</h3>
      <hr class="sfusHR" />
      <p>
        Join the discussion.
        <a [href]="'https://sportfans.us/sfans/suggest-a-topic/'" class="sfansHref01">Share</a>
        what is on your mind about sports.
      </p>
      <div id="demo-accordion-collapse_02" class="midSectionWrapper" role="tablist">
        <myaccordion03></myaccordion03>
      </div>
    </div>

    <div class="col-xs-12 col-md-6">
      <hr class="sfusHR" />
      <h3 class="sfus_H">HELP BUILD THE LIST</h3>
      <hr class="sfusHR" />
      <div class="sfusVideoWrapper">
        <div class="sfusVideo embed-responsive embed-responsive-16by9">

          <video class="responsive-video embed-responsive-item" poster="../assets/images/sportFansWhereVenue720.png"
            controls>
            <source src="../assets/media/intro720p.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <p class="mar12_0_12_0">
        Suggest a sports topic you are passionate about and if other fans like
        it, we'll add it to the discussions area.
      </p>
      <div class="shareButton text-center mar12_0_12_0">
        <a [href]="'https://sportfans.us/sfans/suggest-a-topic/'">
          <button type="button" aria-controls="collapseExample" class="btn btn-brown mar12_0_0" aria-expanded="false">
            Recommend a New Topic or Listing » </button>
        </a>
        <!-- &nbsp; &nbsp;
        <button href="#" type="button" aria-controls="collapseExample" class="btn btn-brown mar12_0_0"
          aria-expanded="false">
          View Listings »</button> -->
        <!-- <a href="#" class="btn btn-lg btn-brown" role="button">  </a> -->
      </div>
    </div>
  </div>

  <div class="sfusBottomSpacer20">&nbsp;</div>
</div>