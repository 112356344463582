<ngb-accordion [closeOthers]="true" activeIds="static-22">

  <ngb-panel id="static-22" class="card">
    <ng-template ngbPanelTitle class="card-header">
      <h5 class="mb-0">NY-NY</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Bronx' )" [routerLink]="['/reviews', review02]"
              class="sfansHref01">
              Bronx Review
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </ngb-panel>
  <ngb-panel id="static-23" class="card">
    <ng-template ngbPanelTitle class="card-header">
      <h5 class="mb-0">NY-SE</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Peekskill' )" [routerLink]="['/reviews', review08]"
              lass="sfansHref10">
              Peekskill Review
            </a>
          </li>

        </ul>
      </div>
    </ng-template>
  </ngb-panel>


</ngb-accordion>