import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'myaccordion01',
  templateUrl: './myaccordion01.component.html',
})
export class MyAccordion01 {
  public constructor(private titleService: Title) { }
  public review05 = '5';
  public review01 = '1';
  //note: need to specify id in links on hompeage so routerlink works

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


}
