<ngb-accordion [closeOthers]="true" activeIds="static-31">
  <ngb-panel id="static-31" class="card">
    <ng-template ngbPanelTitle class="card-header">
      <h5 class="mb-0">Sport Friendly Breweries</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Badsons' )" [routerLink]="['/reviews', review09]"
              class="sfansHref01">
              Badsons [CT]
            </a>
          </li>
          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Two Roads' )" [routerLink]="['/reviews', review03]"
              class="sfanshref01">Two Roads
              [CT]</a>
          </li>
          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Peekskill, NY' )" [routerLink]="['/reviews', review08]"
              class="sfanshref01"> River
              Outpost [Peekskill]</a>
          </li>
        </ul>
      </div>
    </ng-template>
  </ngb-panel>

  <ngb-panel id="static-32" class="card">
    <ng-template ngbPanelTitle class="card-header">
      <h5 class="mb-0">Arenas/Stadiums</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review:  Fenway' )" [routerLink]="['/reviews', review05]"
              class="sfansHref01">Fenway
              Park (Boston)</a>
          </li>

          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Bronx' )" [routerLink]="['/reviews', review02]"
              class="sfanshref01">Yankee
              Stadium (Bronx)</a>
          </li>
        </ul>
      </div>
    </ng-template>
  </ngb-panel>

  <!-- <ngb-panel id="static-33" class="card">

  </ngb-panel> -->

  <ngb-panel id="static-34" class="card">
    <ng-template ngbPanelTitle class="card-header">
      <h5 class="mb-0">Kid Friendly Sport Venues</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="card-body">
        <ul class="list-group">

          <li class="list-group-item">
            <span class="rt-arrows"> » </span><a
              [href]="'https://sportfans.us/sfans/catering-to-families-is-smart-business/'" class="sfanshref01">Catering
              To Families Is Good For Business</a>
          </li>

          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Peekskill, NY' )" [routerLink]="['/reviews', review08]"
              class="sfanshref01"> River
              Outpost [Peekskill, NY]</a>
          </li>

        </ul>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>