import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'mywelcome',
  templateUrl: './mywelcome.component.html',
})
export class MyWelcomeComponent {
  public constructor(private titleService: Title) { }
  public review10 = '10';
  public review01 = '1';
  //note: need to specify id in links on hompeage so routerlink works

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
