<div class="sfusSummaryPage">
  <div class='card' *ngIf='product'>
    <div class='card-header'>
      <h4> {{pageTitle + ': ' + product.productName}} </h4>
    </div>

    <div class='card-body'>
      <div class='row sfusRow text-left'>

        <div class='col-sm-12 col-md-6'>
          <div class='row'>
            <div class='col-md-4 sfusBoldTxt'>Name:</div>
            <div class='col-md-8'>{{product.productName}}</div>
          </div>
          <div class='row'>
            <div class='col-md-4 sfusBoldTxt'>Date:</div>
            <div class='col-md-8'>{{product.releaseDate}}</div>
          </div>
          <div class='row'>
            <div class='col-md-4 sfusBoldTxt'>Summary:</div>
            <div class='col-md-8'>{{product.description}}</div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6">
          <div class="sfusVideoReviewWrapper">
            <div class="sfusVideo embed-responsive embed-responsive-16by9" *ngIf='product && product.productCode'>
              <yt-player [videoId]='product.productCode'></yt-player>
            </div>
            <div class="" *ngIf='product && product.productCode && product.vidtime'>
              <span class="vidtimeText">{{product.vidtime}}</span>
            </div>
            <div *ngIf='product && product.imageUrl'>
              <img class="sfusVidThumb01 " [src]='product.imageUrl' alt="" />
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>


  <div class=' sfusRow sfusLongSummary'>
    <div class="col-12" *ngIf='product && product.longSummary'>
      <h4 class="sfusSummaryH4">Details</h4>
      <div>
        <p class="sfusFullSummary">
          {{product.longSummary}}
        </p>
      </div>

      <div class='sfusRow'>
        <button class='btn btn-outline-secondary' (click)='onBack()' style='width:120px'>
          <i class='fa fa-chevron-left'></i> Reviews List
        </button>
      </div>

      <div>
        <p class="sfusFullSummary">
          &nbsp;
        </p>
      </div>

    </div>
  </div>