<nav class="navbar navbar-expand-md navbar-dark bg-darkBrown">
  <div class="container navContainer flex-center">
    <a class="navbar-brand site-title" routerLink="/">SPORTFANS.us</a>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse  text-right" [ngClass]="{ show: navbarOpen }">
      <ul class="navbar-nav ">


        <li class=" nav-item  text-right">
          <a [href]="'https://sportfans.us/sfans/membership-login/'" class="nav-link">Member Login</a>
        </li>
        <li class=" nav-item  text-right">
          <a [href]="'https://sportfans.us/sfans/membership-join/membership-registration/'"
            class="nav-link">Register</a>
        </li>
        <!-- <li class="nav-item  text-right">
          <a routerLink="regions" class="nav-link">Regions</a>
        </li> -->

      </ul>

      <!-- <button class="btn btn-warning my-2 my-sm-0" [routerLink]="['/regions']">
        Regions
      </button>
      <button class="btn btn-warning my-2 my-sm-0">
        Topics
      </button> -->
    </div>
  </div>
</nav>

<main class="container sfusContainer flex-center">
  <div class=" sfus">
    <router-outlet></router-outlet>
  </div>
</main>

<footer class="footer bg-darkBrown">
  <nav class="navigation" aria-label="site">
    <ul>
      <li class="footerli">
        <a class="nav-link" routerLink="/">Home</a>
      </li>

      <li class="footerli">
        <a class="nav-link" routerLink="/about">About</a>
      </li>
    </ul>
  </nav>
  <p class="footerPar">SPORTFANS.US - Copyright 2019</p>
  <p class="footerPar">
    Photo Credit Eric Tompkins Unsplash. Music credit Bensound.com.
  </p>
</footer>