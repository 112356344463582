import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// import { CommonModule } from "@angular/common";
// CommonModule exported by BrowserModule 
import { ProductListComponent } from './product-list.component';
import { ProductDetailComponent } from './product-detail.component';
// import { ConvertToSpacesPipe } from '../shared/convert-to-spaces.pipe';
import { RouterModule } from '@angular/router';
import { ProductDetailGuard } from './product-detail.guard';
// import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';


import { YtPlayerAngularModule } from 'yt-player-angular';

@NgModule({
  declarations: [
    ProductListComponent,
    ProductDetailComponent,
    // ConvertToSpacesPipe,
  ],
  imports: [
    // CommonModule,
    BrowserModule,
    RouterModule.forChild([
      { path: 'reviews', component: ProductListComponent },
      {
        path: 'reviews/:id',
        canActivate: [ProductDetailGuard],
        component: ProductDetailComponent
      }
    ]),
    FormsModule,
    YtPlayerAngularModule
  ]
})
export class ProductModule { }


