<div class="sfusJumbo">
  <div class="row sfusRow text-left">
    <div class="col-12">
      <hr class="sfusHR" />
      <h3 class="sfus_H">Regions</h3>
      <hr class="sfusHR" />
      <!-- <h3 class="sfus_H">&nbsp;</h3> -->
    </div>
    <div class="col-xs-12 col-md-6">
      <!-- <h3 class="sfus_H">&nbsp;</h3> -->
      <!-- wp:paragraph -->
      <p class="sfus_p">States are often too big, and cities/counties are sometimes too small of an area to search for
        nearby venues. Dividing states into regions, which contain one or more counties, can offer a better method for
        finding venues within traveling distance.</p>
      <!-- /wp:paragraph -->

      <!-- wp:paragraph -->
      <p class="sfus_p">For example, the areas of NY can be divided into logical regions such as NY-NY (NYC), NY-LI
        (Long Island), and NY-SE (South East - around the Hudson Valley).</p>
      <!-- /wp:paragraph -->

      <!-- wp:list -->
      <ul>
        <li>
          <a [href]="'https://sportfans.us/sfans/regions/ct-sw/'" class="sfanshref01">CT-SW</a> - CT
          South West
        </li>
        <li>
          <a [href]="'https://sportfans.us/sfans/regions/ny-li/'" class="sfanshref01">NY-LI</a> - NY Long
          Island
        </li>
        <li>
          <a [href]="'https://sportfans.us/sfans/regions/ny-ny/'" class="sfanshref01">NY-ny</a> - NYC
          (the 5 boroughs)
        </li>
        <li>
          <a [href]="'https://sportfans.us/sfans/regions/ny-se/'" class="sfanshref01">NY-SE</a> - South
          Eastern NY State
        </li>
      </ul>
      <!-- /wp:list -->

      <!-- wp:paragraph -->





      <!-- /wp:paragraph -->
    </div>

    <!--  ================================== Right Col ================================ -->

    <div class="col-xs-12 col-md-6">
      <!-- 4:3 aspect ratio -->
      <div class="">
        <img class="responsive-img"
          src="https://sportfans.us/sfans/wp-content/themes/wpbs-sfans-child/assets/img/NY_regionsWtext01_600x450.png">
      </div>
      <!-- <div class="sfusVideo embed-responsive embed-responsive-16by9">
        <video class="responsive-video embed-responsive-item" poster="../../assets/images/sportFansWhereVenue720.png"
          controls> <source src="../../assets/media/intro720p.mp4" type="video/mp4" /> </video>
      </div> -->

      <p class="mar12_0_12_0 sfus_p">
        Initially, this site will focus on regions in and around the
        greater NY area. The goal will be to expand to other regions as interest in the site grows. If you feel there is
        a region with exceptional venues that should be included, please let us know in the <a
          [href]="'https://sportfans.us/sfans/where-are-the-best-places-to-catch-a-game/'"
          class="sfanshref01">comments</a>.</p>
    </div>
  </div>
</div>