<ngb-accordion [closeOthers]="true" activeIds="static-2">
  <!-- <ngb-panel id="static-1" class="card">

  </ngb-panel> -->
  <ngb-panel id="static-2" class="card">
    <ng-template ngbPanelTitle class="card-header">
      <h5 class="mb-0">CT-SW</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="rt-arrows"> » </span>
            <a (click)="setTitle( 'Sportfans Review: Stamford' )" [routerLink]="['/reviews', review01]"
              class="sfansHref01">Stamford Review</a>
          </li>
        </ul>
      </div>
    </ng-template>
  </ngb-panel>


  <ngb-panel id="static-4" class="card">
    <ng-template ngbPanelTitle class="card-header">
      <h5 class="mb-0">Future Regions</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="rt-arrows"> » </span><a [href]="'https://sportfans.us/sfans/suggest-a-topic/'"
              class="sfanshref01">Suggest a Topic/City/Region</a>
          </li>
          <li class="list-group-item">
            <span class="rt-arrows"> » </span><a
              [href]="'https://sportfans.us/sfans/where-are-the-best-places-to-catch-a-game/'"
              class="sfanshref01">Suggest a Venue</a>
          </li>

        </ul>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>