<div class='card'>
  <div class='card-header'>
    <div class='row sfusProd'>
      <div class='col-sm-12 col-md-6'>
        <h3 class="sfus_H"> {{pageTitle}} </h3>
      </div>
      <div class='col-sm-12 col-md-6 mar9_0_9_0'>
        Filter by: <input type='text' [(ngModel)]='listFilter' />
      </div>
    </div>

  </div>
  <div class='card-body'>

    <div class="row" *ngIf="listFilter">
      <div class='col-md-6'>
        <h4>Filtered by: {{listFilter}}</h4>
      </div>
    </div>
    <div class='table-responsive'>
      <table class='table' *ngIf='products && products.length'>
        <thead>
          <tr>
            <th class="sfusTableCell01">Review</th>
            <th class="sfusTableCell02">Date</th>
            <th class="sfusTableCell03">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let product of filteredProducts'>

            <td class="sfusTableCell01">
              <a [routerLink]="['/reviews', product.productId]" [queryParams]="{filterBy: listFilter}">
                {{ product.productName }}
              </a>
            </td>
            <td class="sfusTableCell02">{{ product.releaseDate }}</td>
            <td class="sfusTableCell03">{{ product.description }}</td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div *ngIf='errorMessage' class='alert alert-danger'>
  Error: {{ errorMessage }}
</div>